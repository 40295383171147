<template>
  <div class="page-box">
    <div class="custom-step">
      <a-steps :current="stepActive" size="small">
        <a-step title="编辑基本信息" />
        <a-step title="编辑商品详情" />
        <a-step title="编辑购买须知" v-if='productType === 2 || productType=== 3 || productType === 4' />
      </a-steps>
    </div>
    <a-spin :spinning="loading">
      <div class="page-content" :class="{collect: isCollect}">
        <goods-publish-step1 :isPlatform='isPlatform' ref="step1Ref" v-show="stepActive === 0" :init-data="detailData" :is-collect="isCollect" @sync-success="getProductDetail" @needVoucherFlag:change='changeNeedVoucherFlag' @syncFlagChange="syncFlagChange" @productTypeChange="productTypeChange" @efffectNotice='efffectNotice' />
        <goods-publish-step2 :isPlatform="isPlatform" ref="step2Ref" v-show="stepActive === 1" :initData="richText" :is-collect="isCollect" :syncFlag="detailData.product && detailData.product.syncFlag!==undefined?detailData.product.syncFlag:false" />
        <goods-publish-step3 :isPlatform='isPlatform' ref="step3Ref" v-show="stepActive === 2" :initData="noticeJson" :is-collect="isCollect" :syncFlag="detailData.product && detailData.product.syncFlag!==undefined?detailData.product.syncFlag:false" @effectBaseData='effectBaseData'/>
      </div>
      <div class="btn-box">
        <a-button @click="handlePrevStep" v-if="stepActive !== 0">上一步</a-button>
        <a-button type="primary" @click="handleNextStep" v-if="stepActive === 0 || (stepActive === 1&&(productType ===2||productType === 3||productType === 4))">下一步</a-button>
        <a-button type="primary" @click="handleSave" :loading="submitting">保存</a-button>
        <a-button @click="$closeCurrentView()">取消</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import GoodsPublishStep1 from './GoodsPublishStep1'
import GoodsPublishStep2 from './GoodsPublishStep2'
import GoodsPublishStep3 from './GoodsPublishStep3'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { goods } from '@/api'
import { isPlatform } from '../../utils/tools'
export default {
  name: 'PageGoodsPublish',

  components: {
    GoodsPublishStep1,
    GoodsPublishStep2,
    GoodsPublishStep3,
  },

  props: {
    productId: String,
    type: String,
    params: String,
  },

  setup (props, { root }) {
    const isCollectEdit = computed(
      () =>
        detailData.value &&
        detailData.value.product &&
        detailData.value.product.shopId !== detailData.value.product.collectShopId
    )
    const isCollect = computed(() => props.type === 'collect' || isCollectEdit.value)
    onMounted(() => {
      if (props.type) getProductDetail()
    })
    const loading = ref(false)
    const detailData = ref({})
    const richText = ref('')
    const noticeJson = ref('')
    const productInformId = ref('')
    async function getProductDetail () {
      loading.value = true
      const { code, msg, data } =
        props.type === 'collect'
          ? await goods.getCollectedProductDetail(JSON.parse(window.atob(props.params)))
          : await goods.getProductDetail(props.productId)
      loading.value = false
      if (code === '00000') {
        data.skuList.forEach(x => {
          if (!x.supplyPrice) {
            x.supplyPrice = 0
          }
        })
        const { productDetail, productInform, ...others } = data
        richText.value = productDetail.detail
        productInformId.value = productInform
          ? productInform.productInformId
            ? productInform.productInformId
            : ''
          : ''
        detailData.value = others
        let noticeObj = productInform
          ? productInform.inform
            ? JSON.parse(productInform.inform)
            : {}
          : {}
        const effectNoticeKey = [
          'productTag',
          'serviceTimeType',
          'serviceStartTime',
          'serviceEndTime',
        ]
        for (let key in others.productGeneral) {
          if (effectNoticeKey.indexOf(key) !== -1 && !(key in noticeObj)) {
            if (key === 'productTag') {
              noticeObj[key] = others.productGeneral[key].split(',')
            } else {
              noticeObj[key] =
                typeof others.productGeneral[key] === 'number'
                  ? String(others.productGeneral[key])
                  : others.productGeneral[key]
            }
          }
        }
        noticeJson.value = JSON.stringify(noticeObj)
        productType.value = detailData.value.productGeneral
          ? detailData.value.productGeneral.type
          : 0
      } else {
        root.$message.error(msg || '商品详情获取失败，请稍后重试')
        root.$closeCurrentView()
      }
    }

    const step1Ref = ref(null)
    const step2Ref = ref(null)
    const step3Ref = ref(null)
    const submitting = ref(false)
    const productType = ref(0)
    const needVoucherFlag = ref(false)
    function changeNeedVoucherFlag (flag) {
      needVoucherFlag.value = flag
    }
    async function handleSave () {
      if (submitting.value) return
      const valid = await step1Ref.value.formValidate()
      const valid1 = await step3Ref.value.formValidate()
      const detail = step2Ref.value.editorContent
      const inform = JSON.stringify(valid1)
      if (!valid || !valid1) return
      if (stepActive.value === 1 && !detail) return root.$message.warning('请编辑商品详情')
      const shopId = getSession(SHOPID)
      const { specOptionList, product, ...others } = valid
      const { product: detailProduct } = detailData.value
      const params = {
        product: {
          shopId,
          supplyShopId:
            detailProduct && detailProduct.supplyShopId ? detailProduct.supplyShopId : shopId,
          collectShopId:
            detailProduct && detailProduct.collectShopId ? detailProduct.collectShopId : shopId,
          supplyProductId:
            detailProduct && detailProduct.supplyProductId ? detailProduct.supplyProductId : '',
          collectProductId:
            detailProduct && detailProduct.collectProductId ? detailProduct.collectProductId : '',
          taxCollectFlag:
            !detailProduct || typeof detailProduct.taxCollectFlag === 'undefined'
              ? false
              : detailProduct.taxCollectFlag,
          ...product,
        },
        productDetail: {
          detail,
        },
        productInform: {
          productInformId: productInformId.value,
          inform,
        },
        specOptionList,
        ...others,
      }
      console.log(params)
      if (props.productId) params.product['productId'] = props.productId
      submitting.value = true
      const { code, msg } = props.productId
        ? await goods.productEdit(params)
        : await goods.productPublish(params)
      submitting.value = false
      if (code !== '00000') return root.$message.error(msg || '保存失败，请稍后重试')
      root.$bus.$emit('PageGoodsList:refresh')
      if (params.product.shopId !== params.product.supplyShopId) {
        root.$bus.$emit('CollectDetail:refresh')
      }
      root.$message.success('保存成功')
      root.$closeCurrentView()
    }

    const stepActive = ref(0)
    async function handleNextStep () {
      const valid = await step1Ref.value.formValidate()
      if (!valid) return
      stepActive.value++
    }
    async function handlePrevStep () {
      if (stepActive.value === 2) {
        const valid = await step3Ref.value.formValidate()
        if (!valid) return
      }
      stepActive.value--
    }
    function syncFlagChange (e) {
      if (detailData.value.product) {
        detailData.value.product.syncFlag = e
      }
      if (e) {
        getProductDetail()
      }
    }
    function productTypeChange (e) {
      productType.value = e
    }
    function efffectNotice (obj) {
      step3Ref.value.changeValueBuStep1(obj)
    }
    async function effectBaseData (obj) {
      if ('productTag' in obj) {
        const valid = await step1Ref.value.formValidate()
        if (valid.productGeneral.productTag.indexOf('3') !== -1) {
          obj['productTag'].push('3')
        }
        obj['productTag'] = obj['productTag'].join(',')
      }
      step1Ref.value.changeValueBuStep3(obj)
    }

    return {
      isCollect,
      productType,
      loading,
      noticeJson,
      detailData,
      richText,
      getProductDetail,
      step1Ref,
      step2Ref,
      step3Ref,
      productTypeChange,
      submitting,
      handleSave,
      stepActive,
      handleNextStep,
      needVoucherFlag,
      syncFlagChange,
      changeNeedVoucherFlag,
      handlePrevStep,
      productInformId,
      efffectNotice,
      effectBaseData,
      isPlatform
    }
  },
}
</script>

<style lang="less" scoped>
.custom-step {
  width: 700px;
  margin: 0 auto;
}
.page-content {
  padding-top: 28px;
  &.collect {
    padding: 0;
  }
}
.btn-box {
  text-align: center;
  ::v-deep .ant-btn {
    margin: 0 6px;
  }
}
</style>

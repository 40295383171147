<template>
  <div class="category-choose-panel">
    <div class="choose-search-wrap">
      <a-input :placeholder="`搜索${title}级类目`" allowClear v-model.trim="searchText">
        <a-icon class="icon-search" slot="prefix" type="search" />
      </a-input>
    </div>
    <div ref="refScrollBox" class="category-choose-list">
      <template v-if="type === 0">
        <div v-for="item in tree" :key="item.value">
          <div class="category-item first-category" @click="item.selected = !item.selected">
            <a-icon :class="['icon-caret', {open: item.selected}]" type="caret-right" />
            <span class="category-item-name" :title="item.label">{{item.label}}</span>
          </div>
          <div v-show="item.selected" class="category-choose-list tree-list">
            <div
              v-for="subItem in item.children"
              :key="subItem.value"
              :class="['category-item', {active: subItem.value === currentCode}]"
              @click="handleCategoryClick(subItem, item)">
              <span class="category-item-name" :title="subItem.label" v-html="subItem.labelBack || subItem.label"></span>
              <a-icon v-if="subItem.childrenFlag && !subItem.loading" class="icon-right" type="right" />
              <a-spin v-if="subItem.loading">
                <a-icon class="icon-loading" slot="indicator" type="loading" spin />
              </a-spin>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="item in tree"
          :key="item.value"
          :class="['category-item', {active: item.value === currentCode}]"
          @click="handleCategoryClick(item)">
          <span class="category-item-name" :title="item.label" v-html="item.labelBack || item.label"></span>
          <a-icon v-if="item.childrenFlag && !item.loading" class="icon-right" type="right" />
          <a-spin v-if="item.loading">
            <a-icon class="icon-loading" slot="indicator" type="loading" spin />
          </a-spin>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { common } from '@/api'
import NP from 'number-precision'

const types = ['一', '二', '三', '四']
export default {
  name: 'CategoryChoosePanel',

  props: {
    type: Number, // 表示是第几级类目（0:一二级，1:三级，2:四级...）
    initData: Object, // 组件数据（包含选中code级类目列表）
    keywordHighlight: Function, // 高亮关键词方法
    hash: Number // 通过此属性判断是否需要更新类目列表位置
  },

  setup (props, { emit, root }) {
    const refScrollBox = ref(null)
    const title = computed(() => types[props.type])
    const currentCode = computed(() => props.type ? props.initData.code : props.initData.code.split('|')[1])
    const treeDefault = ref([])
    const treeSearch = ref([])
    const searchText = ref('')
    const tree = computed(() => searchText.value ? treeSearch.value : treeDefault.value)

    watch(() => props.initData, newVal => {
      if (!newVal || !newVal.list || (!props.type && currentCode.value)) return
      const list = newVal.list
      treeDefault.value = !props.type ? handleInitData(list) : (list || []).map(x => ({
        ...x,
        label: x.name,
        value: x.productCategoryId,
        loading: false
      }))
      searchText.value = ''
    }, { immediate: true, deep: true })

    watch(() => props.hash, async newVal => {
      treeSearch.value = []
      searchText.value = ''
      await root.$nextTick()
      const itemH = document.getElementsByClassName('category-item')[0].offsetHeight
      if (!props.type) {
        const firstSelected = treeDefault.value.findIndex(x => x.value === props.initData.code.split('|')[0]) + 1
        const firstH = firstSelected > 0 ? NP.times(firstSelected, itemH) : 0
        let secondH = 0
        if (currentCode.value) {
          for (let i = 0; i < firstSelected; i++) {
            let childrenLength = 0
            if (i === firstSelected - 1) {
              childrenLength = treeDefault.value[i].children.findIndex(x => x.value === currentCode.value)
            } else if (treeDefault.value[i].selected) {
              childrenLength = treeDefault.value[i].children ? treeDefault.value[i].children.length : 0
            }
            secondH += NP.times(childrenLength, itemH)
          }
        }
        treeDefault.value[firstSelected - 1].selected = true
        await root.$nextTick()
        refScrollBox.value.scrollTop = NP.plus(firstH, secondH)
      } else {
        const beforeLength = treeDefault.value.findIndex(x => x.value === currentCode.value)
        refScrollBox.value.scrollTop = NP.times(beforeLength, itemH)
      }
    })

    watch(() => searchText.value, keyword => {
      if (keyword) {
        treeSearch.value = !props.type ? treeDefault.value.filter(x => x.children && x.children.filter(j => j.label.indexOf(keyword) >= 0).length).map(x => ({ ...x, selected: true, children: x.children.filter(j => j.label.indexOf(keyword) >= 0).map(j => ({ ...j, labelBack: props.keywordHighlight(keyword, j.label) })) })) : treeDefault.value.filter(x => x.label.indexOf(keyword) >= 0).map(x => ({ ...x, labelBack: props.keywordHighlight(keyword, x.label) }))
      } else {
        treeSearch.value = []
      }
    })

    async function handleCategoryClick (current, parent) {
      if (current.value === currentCode.value) return
      const { childrenFlag, value } = current
      const code = parent ? `${parent.value}|${value}` : value
      const label = parent ? `${parent.label}|${current.label}` : current.label
      if (childrenFlag) {
        current.loading = true
        const data = await common.getCategoryByPid(value)
        current.loading = false
        if (data.code !== '00000') return root.$message.error(data.msg || `${current.label}下类目获取失败，请稍后重试`)
        emit('change', {
          code,
          label,
          list: data.data
        })
      } else {
        emit('confirm', { code, label })
      }
    }

    function handleInitData (tree) {
      const result = []
      for (let { children, ...x } of tree) {
        result.push({
          ...x,
          selected: false,
          loading: false,
          children: children && children.length ? handleInitData(children) : undefined
        })
      }
      return result
    }

    return {
      refScrollBox,
      title,
      currentCode,
      tree,
      handleCategoryClick,
      searchText
    }
  }
}
</script>

<style lang="less" scoped>
.category-choose-panel {
  height: 300px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  flex-grow: 1;
  &:not(:first-child) {
    border-left: 0;
  }
  .choose-search-wrap {
    padding: 10px;
  }
  .icon-search {
    font-size: 12px;
    color: #dcdcdc;
  }
  .category-choose-list {
    height: 248px;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
    .first-category+.category-choose-list {
      height: auto;
      overflow: visible;
    }
  }
  .category-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
    overflow: hidden;
    &:hover,
    &.active {
      background-color: #e8effa;
    }
    &.first-category {
      color: #969799;
      padding-left: 7px;
      &:hover {
        background-color: transparent;
      }
    }
    .icon-caret {
      font-size: 12px;
      color: #c8c9cc;
      transform: rotate(0);
      margin-right: 5px;
      transition: transform .25s ease;
      &.open {
        transform: rotate(90deg);
      }
    }
    .category-item-name {
      flex: none;
      width: 145px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 12px;
      ::v-deep em {
        font-style: normal;
        color: @link-color;
      }
    }
    .icon-right {
      font-size: 10px;
      color: #c8c9cc;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
  .first-category+.category-choose-list .category-item {
    padding-left: 24px;
    .category-item-name {
      width: 132px;
    }
  }
  .icon-loading {
    font-size: 14px;
  }
}
</style>

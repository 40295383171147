<template>
  <a-modal
    v-model="visible"
    title="选择商品类目"
    width="840px"
    :maskClosable="false"
    :okButtonProps="{props: {disabled: !hasConfirm}}"
    @ok="handleOk"
  >
    <a-select
      class="custom-select"
      placeholder="请输入类目或类目关键词"
      allowClear
      showSearch
      :showArrow="false"
      style="width: 100%"
      :filter-option="false"
      @search="fetchCategory"
      @change="handleCategorySearchChange"
    >
      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
      <a-select-option v-for="d in categorySearchData" :key="d.value">
        <span class="custom-select-option" v-html="d.text"></span>
      </a-select-option>
    </a-select>
    <a-spin :spinning="categoryLoading">
      <div class="category-box">
        <category-choose-panel
          v-for="i in 4"
          :key="`choosePanel${i}`"
          :type="i - 1"
          :initData="categoryData[i - 1]"
          :hash="selectedHash"
          :keywordHighlight="keywordHighlight"
          @change="handleCategoryChange(i-1, arguments[0])"
          @confirm="handleCategoryConfirm(i-1, arguments[0])"
        />
      </div>
    </a-spin>
    <div class="current-selected category-controls">
      <span class="category-controls__label">已选类目：</span>
      <p class="current-selected-body is-single">{{categorySelected}}</p>
    </div>
  </a-modal>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import debounce from 'lodash/debounce'
import { common } from '@/api'
import CategoryChoosePanel from './CategoryChoosePanel'

export default {
  name: 'ModalCategorySetting',

  components: {
    CategoryChoosePanel
  },

  props: {
    show: Boolean,
    idsMapStr: String
  },

  setup (props, { emit, root }) {
    const hasConfirm = ref(false)
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('update:show', val)
      }
    })
    watch(() => visible.value, data => {
      if (data && props.idsMapStr) handleCategorySearchChange(props.idsMapStr)
    })
    const fetching = ref(false)
    const categorySearchData = ref([])

    const fetchCategory = debounce(async keyword => {
      categorySearchData.value = []
      if (!keyword) return
      fetching.value = true
      let { data } = await common.getCategoryByKeyword(keyword)
      if (data.length) {
        data.forEach(x => {
          if (x.names.length) x.names[x.names.length - 1] = keywordHighlight(keyword, x.names[x.names.length - 1])
        })
      }
      categorySearchData.value = (data || []).map(x => ({
        text: x.names.join(' > '),
        value: x.idsMapStr
      }))
      fetching.value = false
    }, 800)

    const selectedHash = ref(0)
    async function handleCategorySearchChange (value) {
      if (!value) {
        categorySearchData.value = []
        return
      }
      categoryLoading.value = true
      const { code, msg, data } = await common.getSearchProductCategory(value)
      categoryLoading.value = false
      if (code !== '00000') return root.$message.error(msg || '类目获取失败，请稍后重试')
      if (props.idsMapStr) emit('update:idsMapStr', '')
      hasConfirm.value = true
      const firstLevel = data[0].find(x => x.selected) || {}
      data.forEach((x, i) => {
        if (i > 0) {
          const { productCategoryId = '', name = '' } = x.find(x => x.selected) || {}
          // 第一个类目列表有两级所以特殊处理
          if (i === 1) {
            categoryData.value[0].code = `${firstLevel.productCategoryId}|${productCategoryId}`
            categoryData.value[0].label = `${firstLevel.name}|${name}`
          } else {
            // 针对页面中已加载类目和未加载类目分情况处理
            if (categoryData.value[i - 1]) {
              categoryData.value[i - 1].code = productCategoryId
              categoryData.value[i - 1].label = name
              categoryData.value[i - 1].list = x
            } else {
              categoryData.value.push({
                code: productCategoryId,
                label: name,
                list: x
              })
            }
          }
        }
      })
      categoryData.value = categoryData.value.map((m, n) => {
        if (n >= data.length - 1) {
          return {
            code: '',
            label: '',
            list: [],
          }
        } else {
          return m
        }
      })
      selectedHash.value++
    }

    const categoryLoading = ref(false)
    const categoryData = ref([])
    onMounted(() => {
      categoryTreeInit()
    })
    async function categoryTreeInit () {
      categoryLoading.value = true
      const { data } = await common.categoryTreeInit()
      categoryData.value.push({
        code: '',
        label: '',
        list: data
      })
      categoryLoading.value = false
    }

    const categorySelected = computed(() => {
      if (!categoryData.value.filter(x => x.label).length) return ''
      return [...categoryData.value[0].label.split('|'), ...categoryData.value.filter((x, i) => x.label && i).map(x => x.label)].join(' > ')
    })

    function handleCategoryChange (i, { code, label, list }) {
      hasConfirm.value = false
      categoryData.value[i].code = code
      categoryData.value[i].label = label
      if (categoryData.value[i + 1]) {
        categoryData.value[i + 1].list = list
      } else {
        categoryData.value.push({
          code: '',
          label: '',
          list
        })
      }
      resetCategoryOthers(i)
    }

    function handleCategoryConfirm (i, { code, label }) {
      categoryData.value[i].code = code
      categoryData.value[i].label = label
      hasConfirm.value = true
      resetCategoryOthers(i, true)
    }

    function resetCategoryOthers (i, isConfirm) {
      categoryData.value.forEach((item, index) => {
        if (index > i) {
          item.code = ''
          item.label = ''
        }
        if (index > i + 1 || (isConfirm && index > i)) item.list = []
      })
    }

    function handleOk () {
      const codes = [...categoryData.value[0].code.split('|'), ...categoryData.value.filter((x, i) => x.code && i).map(x => x.code)]
      emit('confirm', {
        code: codes[codes.length - 1],
        label: categorySelected.value
      })
      visible.value = false
    }

    function keywordHighlight (keyword, word) {
      if (word.indexOf(keyword) < 0) return word
      return word.split(keyword).join(`<em>${keyword}</em>`)
    }

    return {
      hasConfirm,
      visible,
      fetching,
      categorySearchData,
      fetchCategory,
      selectedHash,
      handleCategorySearchChange,
      categoryLoading,
      categoryData,
      categorySelected,
      handleCategoryChange,
      handleCategoryConfirm,
      handleOk,
      keywordHighlight
    }
  }
}
</script>

<style lang="less" scoped>
.category-box {
  display: flex;
  height: 300px;
  margin-top: 20px;
}
.category-controls {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  .current-selected-body {
    color: #969799;
  }
}
.custom-select ::v-deep em,
.custom-select-option ::v-deep em {
  font-style: normal;
  color: @link-color;
}
</style>

<template>
  <div class="goods-detail-setting">
    <div class="setting-box">
      <div class="preview">
        <p class="title">效果预览</p>
        <div class="preview-content">
          <div>
            <p v-if="form.validDay">
              <span class='label'>有效时间：</span>
              <span class='value' v-if="form.serviceTimeType === '1'">下单后{{form.validDay}}天内有效</span>
              <span class='value' v-if="form.serviceTimeType === '2'">{{form.serviceStartTime}}～{{form.serviceEndTime}}</span>
            </p>
            <p v-if="form.exceptDate"><span class='label'>除外日期：</span><span class='value'>{{form.exceptDate}}</span></p>
            <p v-if="form.useDate"><span class='label'>使用时间：</span><span class='value'>{{form.useDate}}</span></p>
            <p><span class='label'>退款规则：</span><span class='value'>{{form.productTag.indexOf('1') !== -1?'随时退':''}} {{form.productTag.indexOf('2') !== -1?'过期退':''}} {{form.productTag.indexOf('1') === -1 && form.productTag.indexOf('2') === -1 ? '不支持退款':''}}</span></p>
            <p v-if="form.wirteOffType.length"><span class='label'>验证方式：</span><span class='value'>{{form.wirteOffType.indexOf('1') !== -1?'扫码':''}} {{form.wirteOffType.indexOf('2') !== -1?'验证码':''}}</span></p>
            <p v-if="form.appointmentRules"><span class='label'>预约规则：</span><span class='value'>{{form.appointmentRules}}</span></p>
            <p v-if="form.peopleNum"><span class='label'>适用人数：</span><span class='value'>{{form.peopleNum}}人</span></p>
            <p v-if="form.rules"><span class='label'>规则提醒：</span><span class='value'>{{form.rules}}</span></p>
            <p v-if="form.service"><span class='label'>商家服务：</span><span class='value'>{{form.service}}</span></p>
          </div>
        </div>
      </div>
      <div v-show='!isCollect|| (isCollect&&!syncFlag)' class="form-box">
        <div class='tips'>注意：此处维护的规则只在小程序商品详情中起到展示作用，并不会限制具体功能。</div>
        <a-form-model ref="ruleForm" :model="form" :rules="{}" :label-col="labelCol" :wrapper-col="wrapperCol">
          <!-- 有效时间 -->
          <a-form-model-item label="有效时间" prop="validDay" extra="兑换/使用商品的有效期">
            <a-radio-group v-model="form.serviceTimeType" :disabled="isCollect" @change="$emit('effectBaseData', {
              serviceTimeType: form.serviceTimeType
            })">
              <a-radio value="1">
                <span style="padding:  0 8px;">下单后</span>
                <a-select style="width: 120px" :disabled='form.serviceTimeType === "2" || isCollect' placeholder='请选择' v-model="form.validDay"  @change="$emit('effectBaseData', {
              validDay: form.validDay
            })">
                  <a-select-option v-for="h in 30" :key="h" :value="h">{{h}}</a-select-option>
                </a-select>
                <span style="padding:  0 8px;">天内有效</span>
              </a-radio>
              <a-radio value="2" style='margin-top:12px;'>
                <a-range-picker style='width: 400px;' :disabled='form.serviceTimeType === "1"' show-time format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @ok="changeDateRange" v-model="dateRange"  />
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!-- 除外日期 -->
          <a-form-model-item label="除外日期" prop="exceptDate" extra="商品不可使用时间">
            <a-input v-model="form.exceptDate" type="textarea" placeholder='如：周末、法定节假日不可用' />
          </a-form-model-item>
          <!-- 使用时间 -->
          <a-form-model-item label="使用时间" prop="useDate" extra="商品使用时间">
            <a-input v-model="form.useDate" type="textarea" placeholder='如：周一至周日 24小时可用' />
          </a-form-model-item>
          <!-- 退款规则 -->
          <a-form-model-item label="退款规则" prop="productTag">
            <a-checkbox-group v-model="form.productTag" :options="tagOptions" @change="$emit('effectBaseData', {
              productTag: form.productTag
            })"/>
            <div class='tips-box'>
              <span class='label'>随时退：</span>
              <span class='value'>在有效期内允许用户申请退款</span>
            </div>
            <div class='tips-box'>
              <span class='label'>过期退：</span>
              <span class='value'>超过有效期后允许用户申请退款</span>
            </div>
            <div class='tips-red'>注：若”随时退“，”过期退“都不勾选，则说明不支持退款</div>
          </a-form-model-item>
          <!-- 验证方式 -->
          <a-form-model-item label="验证方式" prop="wirteOffType" extra="商品使用方式">
            <a-checkbox-group v-model="form.wirteOffType" :options=" [
              { label: '扫码', value: '1' },
              { label: '验证码', value: '2' }
            ]" />
          </a-form-model-item>
          <!-- 预约信息 -->
          <a-form-model-item label="预约规则" prop="appointmentRules" extra="请在店铺信息中填写客服电话，以便于用户可以联系到您">
            <a-input v-model="form.appointmentRules" type="appointmentRules" placeholder='如；无需预约/请提前一天预约' />
          </a-form-model-item>
          <!-- 适用人数 -->
          <a-form-model-item label="适用人数" prop="peopleNum" extra="每个商品支持使用的人数">
            <a-input type='number' style='width: 180px;' addon-after='人' v-model="form.peopleNum" placeholder="请输入" />
          </a-form-model-item>
          <!-- 除外日期 -->
          <a-form-model-item label="规则提醒" prop="rules" extra="商品到店的使用规则">
            <a-input v-model="form.rules" type="textarea" placeholder='如：全场通用/需您当日一次性体验完毕所有项目' />
          </a-form-model-item>
          <!-- 商家服务 -->
          <a-form-model-item label="商家服务" prop="service" extra="商家可提供的额外服务">
            <a-input v-model="form.service" type="textarea" placeholder='如：免费停车、免费Wifi' />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { productTags } from '@/utils/constants'

export default {
  name: 'GoodsPublishStep2',

  props: {
    initData: String,
    syncFlag: Boolean,
    isCollect: Boolean,
    isPlatform: Boolean
  },

  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      other: '',
      form: {
        validDay: undefined,
        wirteOffType: [],
        exceptDate: '',
        productTag: ['1', '2'],
        serviceTimeType: '1',
        serviceStartTime: '',
        serviceEndTime: '',
      },
      dateRange: null,
      moment,
      tagOptions: productTags.slice(0, 2),
    }
  },
  watch: {
    initData: {
      async handler (newVal) {
        if (newVal) {
          await this.$nextTick()
          this.form = Object.assign(this.form, JSON.parse(newVal))
          this.dateRange = this.form.serviceStartTime && this.form.serviceEndTime ? [moment(this.form.serviceStartTime, 'YYYY-MM-DD HH:mm:ss'), moment(this.form.serviceEndTime, 'YYYY-MM-DD HH:mm:ss')] : null
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    partValidate () {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(new Error('校验失败'))
          }
        })
      })
    },
    async formValidate () {
      try {
        await this.partValidate()
        return this.form
      } catch (e) {
        console.error(e.message || '表单校验失败')
        return ''
      }
    },
    changeValueBuStep1 (obj) {
      Object.assign(this.form, obj)
      this.dateRange = this.form.serviceStartTime && this.form.serviceEndTime ? [moment(this.form.serviceStartTime, 'YYYY-MM-DD HH:mm:ss'), moment(this.form.serviceEndTime, 'YYYY-MM-DD HH:mm:ss')] : null
    },
    changeDateRange (value) {
      this.$emit('effectBaseData', {
        serviceStartTime: value.map((x) => moment(x).format('YYYY-MM-DD HH:mm:ss'))[0],
        serviceEndTime: value.map((x) => moment(x).format('YYYY-MM-DD HH:mm:ss'))[1],
      })
      this.form.serviceStartTime = value.map((x) => moment(x).format('YYYY-MM-DD HH:mm:ss'))[0]
      this.form.serviceEndTime = value.map((x) => moment(x).format('YYYY-MM-DD HH:mm:ss'))[1]
    }
  },
}
</script>

<style lang="less" scoped>
.goods-detail-setting {
  padding-bottom: 20px;
}
.setting-box {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 980px;
  .preview {
    flex: none;
    width: 320px;
    margin-right: 32px;
    .title {
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      background-color: #e5e5e5;
    }
    .preview-content {
      height: 460px;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      background-color: #f9f9f9;
      word-wrap: break-word;
      p {
        margin-bottom: 8px;
        display: flex;
        .label {
          color: #666;
          flex-shrink: 0;
          display: block;
        }
        .value {
          color: #000;
          display: block;
        }
      }
    }
  }
  .form-box {
    flex: 1;
  }
}
::v-deep .ant-form-item {
  margin-bottom: 4px;
}
.tips {
  color: red;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
}
.tips-box {
  font-size: 14px;
  line-height: 24px;
  .label {
    color: #000;
  }
  .value {
    color: #666;
  }
}
.tips-red {
  font-size: 14px;
  color: red;
  line-height: 24px;
}
</style>

<template>
  <div class="goods-detail-setting">
    <div class="setting-box">
      <div class="preview">
        <p class="title">效果预览</p>
        <div class="preview-content">
          <div v-html="editorContent"></div>
        </div>
      </div>
      <div v-show='!isCollect|| (isCollect&&!syncFlag)||isPlatform' class="rich-text" ref="richTextRef"></div>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor'
import ossUpload from '@/utils/oss'

export default {
  name: 'GoodsPublishStep2',

  props: {
    initData: String,
    syncFlag: Boolean,
    isCollect: Boolean,
    isPlatform: Boolean
  },

  data () {
    return {
      editor: null,
      editorContent: ''
    }
  },

  watch: {
    initData: {
      handler (newVal) {
        this.editorContent = newVal
        if (this.editor) this.editor.txt.html(newVal)
      },
      immediate: true
    }
  },

  mounted () {
    this.editor = new E(this.$refs.richTextRef)
    this.editor.customConfig = {
      onchange: html => {
        this.editorContent = html
      },
      menus: [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'backColor',
        'link',
        'justify',
        'emoticon',
        'image',
        'table',
        'video',
        'undo',
        'redo'
      ],
      showLinkImg: false,
      uploadImgMaxSize: 3 * 1024 * 1024,
      uploadImgMaxLength: 5,
      customUploadImg: async (files, insert) => {
        const hide = this.$message.loading('图片上传中...', 0)
        try {
          const data = await Promise.all(files.map(x => ossUpload(x)))
          data.forEach(x => insert(`${process.env.VUE_APP_IMG}${x}`))
        } catch (e) {
          console.error(e)
          this.$message.warn('上传失败')
        } finally {
          hide()
        }
      }
    }
    this.editor.create()
  }
}
</script>

<style lang="less" scoped>
.goods-detail-setting {
  padding-bottom: 20px;
}
.setting-box {
  display: flex;
  justify-content: space-around;
  width: 760px;
  margin: 0 auto;
  .preview {
    flex: none;
    width: 320px;
    .title {
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      background-color: #e5e5e5;
    }
    .preview-content {
      height: 460px;
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;
      background-color: #f9f9f9;
      word-wrap: break-word;
      ::v-deep img+br {
        display: block;
        padding: 4px 0;
        content: " "
      }
      ::v-deep p {
        margin: 0 0 1em
      }
      ::v-deep a {
        color: @link-color
      }
      ::v-deep img {
        background: none;
        max-width: 100%!important;
        min-height: 1px;
        vertical-align: bottom
      }
      ::v-deep img,
      ::v-deep ol {
        width: auto!important;
        height: auto
      }
      ::v-deep blockquote {
        padding: 0 0 0 15px;
        margin: 0 0 18px;
        border-left: 5px solid #eee
      }
      ::v-deep em,
      ::v-deep i {
        font-style: italic
      }
      ::v-deep b,
      ::v-deep strong {
        font-weight: 700
      }
      ::v-deep table {
        margin-bottom: 10px;
        border-collapse: collapse;
        display: table;
        width: auto!important
      }
      ::v-deep td,
      ::v-deep th {
        padding: 5px 10px;
        border: 1px solid #ddd;
        word-break: break-all;
      }
      ::v-deep caption {
        border: 1px dashed #ddd;
        border-bottom: 0;
        padding: 3px;
        text-align: center
      }
      ::v-deep th {
        border-top: 2px solid #bbb;
        background: #f7f7f7
      }
      ::v-deep td p {
        margin: 0;
        padding: 0
      }
    }
  }
  .rich-text {
    flex: none;
    width: 420px;
    ::v-deep .w-e-toolbar {
      flex-wrap: wrap;
      .w-e-menu {
        z-index: unset!important;
      }
      .w-e-droplist {
        z-index: 20000;
      }
    }
    ::v-deep .w-e-text-container {
      height: 436px!important;
    }
    ::v-deep table {
      th, td {
        word-break: break-all;
      }
    }
  }
}
</style>
